$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  margin: 0;
  padding: $mobile_section_vertical_gutter 0;
  position: relative;
  overflow: hidden;
  max-width: 100vw;
  border-top: $border-light-grey;

  @media screen and (min-width: $breakpoint_l) {
    padding: $desktop_section_vertical_gutter 0;
  }

  .linkButton {
    width: 100%;
    white-space: nowrap;
    margin-top: 24px;

    @include text-xs-regular;

    @media screen and (min-width: $breakpoint_l) {
      margin-top: 40px;

      @include text-sm-regular;
    }
  }

  &.theme-white {
    color: $black;
  }

  &.theme-black {
    background-color: $black;
    color: $white;
  }
}

.headerTitle {
  padding-bottom: 24px;

  @include text-4xl;

  @media screen and (min-width: $breakpoint_l) {
    padding-bottom: 40px;

    @include text-6xl;
  }

  @media screen and (min-width: $breakpoint_xl) {
    @include text-7xl;
  }
}

.body {
  position: relative;
  display: block;

  &.noHeader {
    padding-top: 24px;
  }

  @media screen and (min-width: $breakpoint_l) {
    padding-top: 40px;
  }
}
