$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

div.overlay {
  background: rgba(0, 0, 0, 0.6);
}

div.modal {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  overflow: hidden;

  @media screen and (min-width: $breakpoint_l) {
    margin: 48px 0;
    max-width: 616px;
  }

  &.type {
    &-default {
      .title {
        margin-right: 32px;

        @include text-3xl;

        @media screen and (min-width: $breakpoint_l) {
          @include text-5xl;
        }
      }

      .description {
        margin-top: 8px;

        @include text-base-regular;
      }

      &.withActions {
        .entry {
          padding-bottom: 120px;

          @media screen and (min-width: $breakpoint_l) {
            padding-bottom: 92px;
          }
        }
      }
    }

    &-confirm {
      @media screen and (min-width: $breakpoint_l) {
        max-width: 384px;
      }

      .header {
        margin-bottom: 0;
        display: flex;
        align-items: center;
      }

      .title {
        margin-right: 32px;

        @include text-sm-regular;
      }

      .description {
        text-align: center;

        @include text-xl;

        @media screen and (min-width: $breakpoint_l) {
          padding: 60px 0 66px;
        }
      }

      .body {
        justify-content: center;
        flex-grow: 1;
      }

      .mobileBreak {
        margin: 16px -16px 0;

        @media screen and (min-width: $breakpoint_l) {
          display: block;
        }
      }

      .entry {
        padding: 16px 16px 32px;
        display: flex;
        flex-direction: column;
      }

      &.withActions {
        .entry {
          padding: 16px 16px 60px;

          @media screen and (min-width: $breakpoint_l) {
            padding: 16px 16px 0;
          }
        }
      }

      .closeButton {
        top: auto;
      }

      .closeIcon {
        @media screen and (min-width: $breakpoint_l) {
          font-size: 24px;
        }
      }
    }
  }

  &.theme {
    &-white {
      background: $white;

      .closeIcon,
      .title {
        color: $black;
      }

      .description {
        color: $dove-gray;
      }

      .mobileBreak {
        background: $light-gray;
      }

      &.type {
        &-confirm {
          .description {
            color: $black;
          }
        }
      }
    }

    &-black {
      background: $black;

      .closeIcon,
      .title,
      .description {
        color: $white;
      }

      .mobileBreak {
        background: $mine-shaft;
      }

      &.type {
        &-confirm {
          .description {
            color: $white;
          }
        }
      }
    }
  }
}

.entry {
  padding: 24px 16px;
  height: 100%;
  min-height: 100vh;

  @media screen and (min-width: $breakpoint_l) {
    padding: 32px;
    min-height: auto;
  }
}

.header {
  position: relative;
  margin-bottom: 24px;
}

.mobileBreak {
  display: block;
  margin: 0 -16px 24px;
  height: 1px;
  width: calc(100% + 32px);

  @media screen and (min-width: $breakpoint_l) {
    display: none;
  }
}

.body {
  display: flex;
  flex-direction: column;
}

.actions {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;

  @media screen and (min-width: $breakpoint_l) {
    position: relative;
  }

  & > button {
    flex-grow: 1;
    width: 100%;
  }
}

.closeButton {
  position: absolute;
  right: 0;
  top: 1px;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  @include flexCenter;

  @media screen and (min-width: $breakpoint_l) {
    right: -8px;
    top: -8px;
  }
}

.closeIcon {
  font-size: 24px;

  @media screen and (min-width: $breakpoint_l) {
    font-size: 32px;
  }
}
