$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.header {
  @include text-body1Regular16;

  margin: 0 0 12px;
}

.body {
  margin-bottom: $header_height;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 12px;
}

.bodySection {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 4px;
}

.paymentSection {
  padding-top: 32px;
}

.footer {
  min-height: $header_height;
  position: absolute;
  right: 8px;
  bottom: 8px;
  left: 8px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: stretch;
}

.amountInput {
  input {
    @include number_no_arrows;
  }
}
