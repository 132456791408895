$cdn: 'https://cdn.millions.co';
@import "styles/scss/_base";
@import "styles3/scss/_base";

div.overlay {
  padding: 0;
  background: rgba(0, 0, 0, 0.8);

  @media screen and (min-width: $breakpoint_l) {
    padding: 24px;
  }
}

div.modal {
  margin: 0;
  display: flex;
  flex-direction: column;
  border-radius: $radius-1;
  border: $border-1 solid $border-default;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  padding: 24px;
  background-color: $background-default;

  @media screen and (min-width: $breakpoint_l) {
    display: inline-block;
    margin: auto;
    min-height: unset;
    padding: 74px 46px;
    border-radius: 8px;
    max-width: 585px;
  }

  .closeButton {
    fill: $lights-medium;
    top: 20px;
    right: 20px;
  }
}

.modalContent {
  margin: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 380px;
}

.title {
  margin-bottom: 16px;
  font-weight: $semi_bold_font_weight;
  text-align: center;
  letter-spacing: -0.4px;
  color: $lights-high;

  @include font_size(24px);
  @include line_height(32px);

  &.titleWithoutImageAbove {
    margin-bottom: 8px;
    letter-spacing: -0.8px;

    @include font_size(36px);
    @include line_height(46px);
  }
}

.highlighted {
  display: block;
}

.description {
  font-weight: $regular_font_weight;
  text-align: center;
  letter-spacing: -0.2px;
  color: $lights-high;

  @include font_size(16px);
  @include line_height(22px);
}

.imageWrapper {
  margin-bottom: 32px;
}

.image {
  margin: 0 auto;
  width: 100%;
  max-width: 140px;
  height: auto;
}

.actions {
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.success {
  color: $success-default;
}

.error {
  color: $error-default;
}

.confirm {
  color: $accent-default;
}

.button {
  margin: 0 16px;
}

.childrenWrapper {
  margin: 32px 0 0;
  width: 100%;
}
