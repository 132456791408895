// Interface colors
$white: #ffffff;
$black: #000000;
$light-gray: #e5e5e5;
$cultured: #f4f4f4;
$dusty-grey: #999999;
$dim-grey: #6f6f6f;
$grey: #808080;
$gainsboro: #dddddd;
$alto: #d5d5d5;
$god-grey: #1a1a1a;
$harvest-gold: #d2ab64;
$harvest-gold-dark: #ac7104;
$harvest-gold-light: #dbbc83;
$chateau-green: #3ab45a;
$maximum-red: #dc2626;
$hover-color: #d2ab64;
$red: #d45858;
$signal-red: #f43f36;
$signal-green: #06c270;
$signal-red: #f43f36;
$border-black: #202127;
$off-black: #141519;
$dark-grey: #8e8e8e;

$interface_colors: (
  "white": $white,
  "light-white": rgba($white, 0.7),
  "black": $black,
  "black-gold": $black,
  "light-gray": $light-gray,
  "cultured": $cultured,
  "dusty-grey": $dusty-grey,
  "gainsboro": $gainsboro,
  "alto": $alto,
  "god-grey": $god-grey,
  "harvest-gold": $harvest-gold,
  "chateau-green": $chateau-green,
  "maximum-red": $maximum-red,
  "transparent-gold": $harvest-gold,
  "transparent": transparent,
);

// Other colors
$platinum: #e5e5e5;
$concrete: #f2f2f2;
$main-gray: #f7f7f7;
$alto-light: #cccccc;
$neutral-10: #dddcda;
$neutral-20: #dddddd;
$neutral-30: #b0aeaa;
$silver-gray: #bab9b9;
$silver: #c4c4c4;
$dove-gray: #666666;
$light-gray: #d9d9d9;
$pastel-grey: #dfdfdf;
$silver-chalice: #a8a8a8;
$mine-shaft: #333333;
$dark: #1f2228;
$chinese-black: #101010;
$salem: #16a34a;
$light-harvest-gold: #faf7ef;
$acceptGreen: #06c270;
$hostCard: #202127;
$hostCardBorder: #141519;

// Indents
$desktop_header_height: 80px;
$mobile_header_height: 72px;
$desktop_footer_height: 222px;
$mobile_footer_height: 395px;
$desktop-stream-chat-width: 280px;
$mobile-stream-chat-height: 244px;
$mobile_gutter: 16px;
$desktop_gutter: 96px;
$mobile_section_vertical_gutter: 40px;
$desktop_section_vertical_gutter: 64px;
$desktop_main-component_bottom-padding: 40px;
$mobile_main-component_bottom-padding: 80px;
$input-container-x-padding-large: 12px;
$input-container-x-padding-medium: 8px;
$input-container-x-padding-small: 6px;
$grid-row-gutter: 80px;
$profile-nav-width: 220px;
$max-resolution-size: 1440px;

// Font sizes
$regular_font_weight: 400;
$medium_font_weight: 500;
$semi_bold_font_weight: 600;
$bold_font_weight: 700;

// Breakpoints
$breakpoint_xs: 480px;
$breakpoint_s: 512px;
$breakpoint_m: 768px;
$breakpoint_ml: 991px;
$breakpoint_l: 1024px;
$breakpoint_xl: 1280px;
$breakpoint_xxl: 1440px;

// Borders
$border-grey: 1px solid $mine-shaft;
$border-error: 1px solid $maximum-red;
$border-light-grey: 1px solid $light-gray;
$border-transparent: 1px solid rgba(255, 255, 255, 0.2);
$border-secondary: 1px solid $alto-light;
$border-basic: 1px solid $concrete;

// Other
$transition-basic: all 0.2s ease-in-out;
