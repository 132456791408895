// -- Primary Colors --
// Accent
$accent-default: #d8a445;
$accent-bright: #dcb46d;
$accent-dim: #ab833e;
$accent-main: #ffeac2;
// Background
$background-default: #141519;
$background-light: #202127;
$background-dim: #0a0a0c;
$background-main: #000000;
// Lights
$lights-high: #ffffff;
$lights-medium: #d9d9d9;
$lights-low: #999999;
$lights-dim: #666666;
// Border
$border-default: #27282d;
$border-bright: #525254;
// -- Secondary Colors --
// Purple
$purple-default: #8e72dc;
$purple-tint: #8e72dc1a;
// Turquoise
$turquoise-default: #009b9b;
$turquoise-tint: #009b9b1a;
// Blue
$blue-default: #0066ff;
$blue-tint: #0066ff1a;
// -- Alert Colors --
// Warning
$warning-default: #ffab00;
$warning-bright: #ffda8e;
$warning-tint: #ffab001a;
$warning-dim: #9f6b00;
// Error
$error-default: #f43f36;
$error-bright: #ffb2b6;
$error-tint: #f43f361a;
$error-dim: #b81c15;
// Success
$success-default: #06c270;
$success-bright: #b2ffde;
$success-tint: #06c2701a;
$success-dim: #00854b;
// Gradient
$gradient-default: #ff9900;
$gradient-dim: #ffbb00;
$gradient-bright: #ffcf4d;

// Border
$border-0: 0.5px;
$border-1: 1px;
$border-2: 1.5px;
$border-3: 2px;

// Border Radius
$radius-0: 3px;
$radius-1: 5px;
$radius-2: 10px;
$radius-3: 20px;
$radius-4: 30px;
$radius-5: 40px;

// Spacings
$spacing-0: 2px;
$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 12px;
$spacing-4: 16px;
$spacing-5: 24px;
$spacing-6: 32px;
$spacing-7: 40px;
$spacing-8: 48px;
$spacing-9: 64px;
$spacing-10: 80px;
$spacing-11: 96px;
$spacing-12: 160px;

// Breakpoints
$breakpoint_xs: 480px;
$breakpoint_s: 512px;
$breakpoint_m: 768px;
$breakpoint_ml: 991px;
$breakpoint_l: 1024px;
$breakpoint_xl: 1280px;
$breakpoint_xxl: 1440px;

// General variables
$header_height: 72px;

$colors: (
  "accent": (
    "default": $accent-default,
    "bright": $accent-bright,
    "dim": $accent-dim,
  ),
  "background": (
    "default": $background-default,
    "light": $background-light,
    "dim": $background-dim,
  ),
  "lights": (
    "high": $lights-high,
    "medium": $lights-medium,
    "low": $lights-low,
    "dim": $lights-dim,
  ),
  "border": (
    "default": $border-default,
    "bright": $border-bright,
  ),
  "purple": (
    "default": $purple-default,
    "tint": $purple-tint,
  ),
  "turquoise": (
    "default": $turquoise-default,
    "tint": $turquoise-tint,
  ),
  "blue": (
    "default": $blue-default,
    "tint": $blue-tint,
  ),
  "warning": (
    "default": $warning-default,
    "bright": $warning-bright,
    "tint": $warning-tint,
    "dim": $warning-dim,
  ),
  "error": (
    "default": $error-default,
    "bright": $error-bright,
    "tint": $error-tint,
    "dim": $error-dim,
  ),
  "success": (
    "default": $success-default,
    "bright": $success-bright,
    "tint": $success-tint,
    "dim": $success-dim,
  ),
);

$border: (
  "0": $border-0,
  "1": $border-1,
  "2": $border-2,
  "3": $border-3,
);

$radius: (
  "0": $radius-0,
  "1": $radius-1,
  "2": $radius-2,
  "3": $radius-3,
  "4": $radius-4,
  "5": $radius-5,
);

$spacing: (
  "0": $spacing-0,
  "1": $spacing-1,
  "2": $spacing-2,
  "3": $spacing-3,
  "4": $spacing-4,
  "5": $spacing-5,
  "6": $spacing-6,
  "7": $spacing-7,
  "8": $spacing-8,
  "9": $spacing-9,
  "10": $spacing-10,
  "11": $spacing-11,
  "12": $spacing-12,
);

// Transitions
$transition-basic: all 0.2s ease-in-out;

// Default card size
$cardSizes: (
  small: 120px,
  medium: 170px,
  large: 344px,
);
