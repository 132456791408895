$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.section {
  padding: 66px $mobile_gutter 48px;
  margin-top: 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $black;

  @media screen and (min-width: $breakpoint_l) {
    padding: 66px 66px 48px;
    margin: 108px -120px 0;
  }
}

.streamerAvatar {
  width: 100px;
  height: 100px;
  position: absolute;
  top: -50px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}

.title {
  position: relative;
  margin-bottom: 44px;
  color: $white;
  font-weight: $semi_bold_font_weight;
  text-transform: uppercase;

  @include text-2xl;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 48px;
    font-weight: $bold_font_weight;

    @include text-4xl;
  }

  &::after {
    position: absolute;
    content: "";
    height: 8px;
    width: 60px;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $harvest-gold;

    @media screen and (min-width: $breakpoint_l) {
      height: 4px;
    }
  }
}

.text {
  margin-bottom: 32px;
  max-width: 790px;
}
