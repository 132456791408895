$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.shareProfileButton {
  width: 100%;
  display: block;
  border: none;
  color: $background-dim;
  text-transform: uppercase;

  @include text-body2Medium;
  @extend %button-reset;
}

.shareButton {
  flex-grow: 1;
  height: 34px;
  width: 100%;

  @media screen and (min-width: $breakpoint_m) {
    height: 64px;
  }

  span {
    font-size: 12px;
    gap: 5px;
    height: 100%;

    @media screen and (min-width: $breakpoint_m) {
      font-size: 20px;
    }
  }

  &[class*="button"] {
    border: $border-1 solid $border-bright;
  }
}

.smallButton {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.articleStyle {
  border: 1px solid $border-default !important; // no room for specificity, needed to put important
  color: $background-dim;
}

.shareIcon {
  margin-right: 10px;
}

.modal {
  margin: $header_height 0 0;

  @media screen and (min-width: $breakpoint_m) {
    margin: 0;
  }
}

.entry {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 255px;
  width: 255px;
  background-size: cover;
  background-image: url("../../../public/images/qr-code/qr-mask.png");
  background-repeat: no-repeat;
}

.tag {
  margin: $spacing-6 $spacing-5 $spacing-7;
  color: $lights-high;
  text-align: center;

  @include text-h6;
}

.socialLinks {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialItem {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:nth-child(2) {
    margin: 0 25px;

    @media screen and (min-width: $breakpoint_m) {
      margin: 0 50px;
    }
  }
}

.socialButton {
  margin-bottom: $spacing-3;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $background-dim;
  background-color: $lights-high;
  border-radius: 30px;
  border: 1px solid $border-default;
  transition: box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.itemName {
  color: $lights-high;

  @include text-subtitle3;
}
