$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";
@import "styles3/scss/_base";

.addPaymentBody {
  padding: 0 0 40px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(1, [col-start] 1fr);
}

.addPaymentElementsGroup {
  display: grid;
  grid-gap: $mobile_gutter;
  grid-template-columns: repeat(2, [col-start] 1fr);
}

.addPaymentElementLabel {
  @extend %label;
}

.addPaymentElementInput {
  @extend %input;

  &[class*="invalid"] {
    border-color: $error-tint;
  }
}

.addPaymentFooter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.addPaymentCta {
  white-space: nowrap;
}
