$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";
@import "styles3/scss/base";

.streamPlaylist {
  margin-bottom: 30px;
  padding: 0;
  margin-top: 20px;
}

.infoWrapper {
  margin-bottom: 60px;
}

.title {
  color: $lights-high;
  font-size: 24px;

  @media screen and (min-width: $breakpoint_l) {
    padding: 15px 0;
    @include text-5xl;
    word-wrap: break-word;
    text-transform: none;
  }
}

.createdAt {
  color: $lights-high;
  padding: 10px 0;

  @include text-sm-medium-uppercase;
  font-weight: 400;

  @media screen and (min-width: $breakpoint_l) {
    font-size: 22px;
  }
}

.description {
  margin-bottom: 24px;
  color: $lights-medium;

  @include text-sm-medium;

  @media screen and (min-width: $breakpoint_l) {
    padding: 30px 0;
    font-size: 24px;
    line-height: 25px;
  }

  a {
    text-decoration: underline;
  }
}

.shopButtons {
  display: flex;
  justify-content: flex-end;
  gap: 17px;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_m) {
    flex-direction: row;
  }
}

.buyButton,
.tipButton {
  width: auto;
  height: 34px;

  @media screen and (min-width: $breakpoint_m) {
    height: 64px;
  }

  span {
    font-size: 12px;
    gap: 5px;
    height: 100%;

    @media screen and (min-width: $breakpoint_m) {
      font-size: 20px;
    }
  }
}

.tipIcon {
  margin-right: 10px;
}

.buyCartIcon {
  fill: none;
  margin-right: 10px;
}
