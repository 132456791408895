$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";
@import "styles3/scss/_base";

$logo-size: 120px;
$logo-size_mobile: 100px;

.root {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px 0;

  @media screen and (max-width: $breakpoint_xl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: $breakpoint_m) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

.logoAndTextWrapper {
  display: flex;
  align-items: center;
}

.logoWrapper {
  width: $logo-size_mobile;
  height: $logo-size_mobile;
  min-width: $logo-size_mobile;
  min-height: $logo-size_mobile;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  overflow: hidden;

  @media screen and (min-width: $breakpoint_l) {
    width: $logo-size;
    height: $logo-size;
    min-width: $logo-size;
    min-height: $logo-size;
  }
}

.logoImage {
  width: $logo-size;
  height: $logo-size;
  min-width: $logo-size;
  min-height: $logo-size;
}

.storeName {
  margin-bottom: 16px;
  font-weight: $semi_bold_font_weight;
  color: $lights-high;
  text-transform: uppercase;
  letter-spacing: -0.22px;

  @include font_size(22px);
  @include line_height(30px);
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: $spacing-5;
}

.viewMoreBtn {
  margin-top: 20px;
  width: 100%;
}

.section {
  > div {
    padding-left: 0;
    padding-right: 0;
  }

  margin: $spacing-8 0;
}
