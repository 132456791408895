$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  position: relative;
}

.slider {
  width: 100%;

  [class*="slick-track"] {
    display: flex;
  }

  [class*="slick-slide"] {
    // height: inherit;

    & > div {
      height: auto;
      margin: 0 $spacing-4 0 0;

      @media screen and (min-width: $breakpoint_l) {
        margin: 0 $spacing-5 0 0;
      }
    }
  }
}
