$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  margin: 0;
  padding: $mobile_section_vertical_gutter 0;
  position: relative;
  overflow: hidden;
  max-width: 100vw;
  background-color: $black;
  border-bottom: $border-grey;

  @media screen and (min-width: $breakpoint_l) {
    padding: $desktop_section_vertical_gutter 0;
  }
}

.headerTitle {
  margin-bottom: 24px;

  @include text-4xl;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 40px;

    @include text-7xl;
  }
}

.form {
  margin-bottom: 16px;

  @media screen and (min-width: $breakpoint_l) {
    margin-bottom: 24px;
  }

  .inputWrapper {
    width: 100%;

    position: relative;
    display: inline-block;

    @media screen and (min-width: $breakpoint_l) {
      width: 500px;
    }

    @media screen and (min-width: $breakpoint_xl) {
      width: 648px;
    }
  }

  .input {
    width: 100%;
    background: $white;
    border: 1px solid $white;
    outline: none;
    padding: 14px;

    @include text-xs-regular;

    &::placeholder {
      text-transform: uppercase;
    }

    @media screen and (min-width: $breakpoint_l) {
      padding: 20px 14px;

      @include text-sm-regular;
    }
  }

  .error {
    border: $border-error;

    &::placeholder {
      color: $maximum-red;
    }
  }

  .errorLabel {
    position: absolute;
    left: 14px;
    bottom: -20px;
    color: $maximum-red;

    @include text-sm-regular;
  }

  .submitButton {
    width: 100%;

    @media screen and (min-width: $breakpoint_l) {
      width: 276px;
    }
  }
}

.description {
  opacity: 0.7;

  @include text-xs-regular;

  @media screen and (min-width: $breakpoint_l) {
    @include text-sm-regular;
  }
}
