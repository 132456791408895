// Text
@mixin font_size($pixels) {
  font-size: $pixels;
  font-size: rem($pixels);
}

@mixin line_height($pixels) {
  line-height: $pixels;
  line-height: rem($pixels);
}

@mixin inline_overflow_ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin line_clamp($lines: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  word-break: normal;
  overflow: hidden;
  hyphens: auto;
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin text-9xl {
  font-family: "GT America";
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: 700;

  @include font_size(128px);
  @include line_height(115px);
}

@mixin text-8xl {
  font-family: "GT America";
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: 700;

  @include font_size(96px);
  @include line_height(86px);
}

@mixin text-7xl {
  font-family: "GT America";
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: 700;

  @include font_size(72px);
  @include line_height(65px);
}

@mixin text-6xl {
  font-family: "GT America";
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: 700;

  @include font_size(60px);
  @include line_height(54px);
}

@mixin text-5xl {
  font-family: "GT America";
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: 700;

  @include font_size(48px);
  @include line_height(43px);
}

@mixin text-4xl {
  font-family: "GT America";
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: 700;

  @include font_size(36px);
  @include line_height(32px);
}

@mixin text-3xl {
  font-family: "GT America";
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: 700;

  @include font_size(30px);
  @include line_height(27px);
}

@mixin text-2xl {
  font-family: "GT America";
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: 700;

  @include font_size(24px);
  @include line_height(22px);
}

@mixin text-xl {
  font-family: "GT America";
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: 700;

  @include font_size(20px);
  @include line_height(18px);
}

// Text LG
@mixin text-lg-medium {
  font-family: "GT America";
  font-weight: 500;
  letter-spacing: initial;

  @include font_size(18px);
  @include line_height(22px);
}

@mixin text-lg-regular {
  font-family: "GT America";
  font-weight: 400;
  letter-spacing: -0.01em;

  @include font_size(18px);
  @include line_height(25px);
}

@mixin text-lg-regular-underline {
  text-decoration: underline;

  @include text-lg-regular;
}

// Text BASE
@mixin text-base-medium {
  font-family: "GT America";
  font-weight: 500;
  letter-spacing: 0.02em;

  @include font_size(16px);
  @include line_height(19px);
}

@mixin text-base-regular {
  font-family: "GT America";
  font-weight: 400;
  letter-spacing: -0.01em;

  @include font_size(16px);
  @include line_height(22px);
}

@mixin text-base-regular-underline {
  text-decoration: underline;

  @include text-base-regular;
}

// Text SM
@mixin text-sm-medium {
  font-family: "GT America";
  font-weight: 500;
  letter-spacing: 0.02em;

  @include font_size(14px);
  @include line_height(17px);
}

@mixin text-sm-medium-uppercase {
  font-family: "GT America";
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  @include font_size(14px);
  @include line_height(18px);
}

@mixin text-sm-regular {
  font-family: "GT America";
  font-weight: 400;
  letter-spacing: 0.02em;

  @include font_size(14px);
  @include line_height(17px);
}

@mixin text-sm-regular-underline {
  text-decoration: underline;

  @include text-sm-regular;
}

// Text XS
@mixin text-xs-medium-uppercase {
  font-family: "GT America";
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  @include font_size(12px);
  @include line_height(14px);
}

@mixin text-xs-medium-uppercase-underline {
  text-decoration: underline;

  @include text-xs-medium-uppercase;
}

@mixin text-xs-regular {
  font-family: "GT America";
  font-weight: 400;
  letter-spacing: 0.02em;

  @include font_size(12px);
  @include line_height(14px);
}

@mixin text-xs-regular-underline {
  text-decoration: underline;

  @include text-xs-regular;
}

@mixin text_sizes {
  &-extra-small {
    @include text-xs-regular;
  }

  &-small {
    @include text-sm-regular;
  }

  &-medium {
    @include text-base-regular;
  }

  &-large {
    @include text-lg-regular;
  }

  &-extra-large {
    @include text-xl;
  }

  &-2x-large {
    @include text-2xl;
  }

  &-3x-large {
    @include text-3xl;
  }

  &-4x-large {
    @include text-4xl;
  }

  &-5x-large {
    @include text-5xl;
  }
}

// Buttons
@mixin button_size_xSmall {
  padding: 8px 14px 7px;

  @include text-xs-medium-uppercase;

  .buttonIcon {
    @include font_size(12.8px);
  }

  &.withEndIcon {
    .buttonIcon {
      margin-left: 4.59px;
    }
  }

  &.withStartIcon {
    .buttonIcon {
      margin-right: 4.59px;
    }
  }
}

@mixin button_size_small {
  @include button_size_xSmall;

  padding: 11px 20px;
}

@mixin button_size_default {
  padding: 20px 32px;

  @include text-sm-medium-uppercase;

  .buttonIcon {
    @include font_size(18.3px);
  }

  &.withEndIcon {
    .buttonIcon {
      margin-left: 8.85px;
    }
  }

  &.withStartIcon {
    .buttonIcon {
      margin-right: 8.85px;
    }
  }
}

@mixin button_sizes {
  &-xSmall {
    @include button_size_xSmall;
  }

  &-small {
    @include button_size_small;
  }

  &-default {
    @include button_size_default;
  }
}

// Input
@mixin number_no_arrows {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
}

// Grid
@mixin even_grid_columns($number) {
  grid-template-columns: repeat($number, minmax(0, 1fr));
}

// Image
@mixin standard_image {
  margin: auto;
  display: block;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center center;
}
