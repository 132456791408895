$white: #ffffff;
$athens-gray: #f9f9fa;
$alabaster: #fbfbfb;
$concrete: #f2f2f2;
$light-gray: #e5e5e5;
$main-gray: #f7f7f7;
$mystic: #e6ecef;
$alto-light: #cccccc;
$alto: #d5d5d5;
$silver-gray: #bab9b9;
$silver-chalice: #adadad;
$silver: #c4c4c4;
$super-light-grey: #aaaaaa;
$mountain-mist: #9a9893;
$storm-gray: #75777d;
$dove-gray: #666666;
$dune: #52504c;
$dusty-grey: #999999;
$mid-grey: rgb(92, 93, 104);
$blue-grey: rgb(126, 148, 183);
$mine-shaft: #333333;
$black-magic: #282725;
$dark: #1f2228;
$dark-grey: #1a1c1f;
$space-grey: #1c1c1c;
$black: #000000;
$harvest-gold-dark: #ac7104;
$harvest-gold: #d2ab64;
$harvest-gold-light: #dbbc83;
$harvest-gold-2x-light: #e4cda2;
$harvest-gold-3x-light: #edddc1;
$harvest-gold-4x-light: #f6eee0;
$harvest-gold-5x-light: #fcfbf7;
$sandy-yellow: #f2ea75;
$cyan: #5dcbdf;
$green: #5ddfc0;
$chateau-green: #3ab45a;
$pigment-green: #1baf4e;
$cosmic-latte: #d3f8e0;
$salad: #73c64c;
$salad-light: #8fd170;
$salad-2x-light: #abdd93;
$salad-3x-light: #c7e8b7;
$salad-4x-light: #e3f4db;
$burnt-sienna: #eb805e;
$red: #d45858;
$rose-red: #e15555;
$rose-red-light: #e77777;
$rose-red-2x-light: #ed9999;
$rose-red-3x-light: #f3bbbb;
$rose-red-4x-light: #f9dddd;
$border-black: #202127;

// neutral
$neutral-10: #dddcda;
$neutral-30: #b0aeaa;

$button_border_color: $sandy-yellow;
$warning: $red;
$success: $green;

$desktop_header_height: 88px;
$mobile_header_height: 68px;
$desktop_header_height-padding: 40px;
$mobile_header_height-padding: 22px;
$desktop_footer_height: 222px;
$mobile_footer_height: 395px;
$desktop_main-component_top-padding: 22px;
$mobile_main-component_top-padding: 40px;
$desktop_main-component_bottom-padding: 40px;
$mobile_main-component_bottom-padding: 80px;
$profile-nav-width: 220px;
$desktop_gutter: 44px;
$desktop_gutter_new: 120px;
$mobile_gutter: 16px;

$grid-col-gutter: 30px;
$grid-col-gutter-mobile: 16px;
$grid-row-gutter: 80px;
$grid-row-gutter-mobile: 40px;

$interface_colors: (
  "white": $white,
  "alabaster": $alabaster,
  "light-gray": $light-gray,
  "mystic": $mystic,
  "super-light-grey": $super-light-grey,
  "storm-gray": $storm-gray,
  "mid-grey": $mid-grey,
  "blue-grey": $blue-grey,
  "dark": $dark,
  "dove-gray": $dove-gray,
  "dark-grey": $dark-grey,
  "space-grey": $space-grey,
  "black": $black,
  "black-gold": $black,
  "harvest-gold": $harvest-gold,
  "sandy-yellow": $sandy-yellow,
  "cyan": $cyan,
  "green": $green,
  "chateau-green": $chateau-green,
  "salad": $salad,
  "red": $red,
  "rose-red": $rose-red,
  "orange": $burnt-sienna,
  "transparent": transparent,
  "dune": $dune,
  "black-magic": $black-magic,
  "mountain-mist": $mountain-mist,
  "pigment-green": $pigment-green,
  "cosmic-latte": $cosmic-latte,
  "transparent-gold": $harvest-gold,
);

$extra_small_font_size: 10px;
$small_font_size: 12px;
$medium_font_size: 14px;
$base_font_size: 16px;
$middle_font_size: 24px;
$large_font_size: 32px;
$extra_large_font_size: 56px;

$regular_font_weight: 400;
$medium_font_weight: 500;
$semi_bold_font_weight: 600;
$bold_font_weight: 700;
$extra_bold_font_weight: 800;
$black_bold_font_weight: 900;

$breakpoint_2xs: 320px;
$breakpoint_xs: 480px;
$breakpoint_s: 512px;
$breakpoint_m: 768px;
$breakpoint_ml: 991px;
$breakpoint_l: 1024px;
$breakpoint_xl: 1280px;
$breakpoint_xxl: 1440px;
$custom_breakpoint: 1140px;

$border-basic: 1px solid $concrete;
$border-secondary: 1px solid $alto-light;

$transition-basic: all 0.2s ease-in-out;
