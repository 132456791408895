$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  margin-bottom: 30px;
  background-color: $black;
  display: flex;
  height: unset;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_m) {
    flex-direction: row;
    height: 600px;
  }
}

.videoContainer {
  width: 100%;
  height: 100%;

  @media screen and (min-width: $breakpoint_m) {
    width: 80%;
  }
}

.playlistContainer {
  position: relative;
  bottom: 0;
  left: 0;
  width: 95%;
  padding: 8px 8px 30px;
  margin: auto;

  @media screen and (min-width: $breakpoint_m) {
    width: 20%;
    padding: 8px;
    overflow-y: auto;
    margin: unset;
    height: 100%;
  }
}

.playlistTitle {
  padding-bottom: 10px;
  color: $white;

  @include text-2xl;
}

.playlistCardsContainer {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint_m) {
    flex-direction: column;
  }
}

.card {
  position: relative;
  background: $white;
  height: auto;
  width: 100%;
  outline: none;
  line-height: 0;
  margin-bottom: 8px;

  &.active {
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: $harvest-gold;
      opacity: 0.6;
      z-index: 1;
    }
  }
}

.cardVideo {
  width: 100%;
  height: auto;
  object-fit: cover;
  background-color: $black;
  border: 1px solid $gainsboro;
}

.videoButton {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: none;
  border: none;
  outline: none;
}

.videoIcon {
  backface-visibility: hidden;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.pauseIcon {
  width: 56px;
  height: 56px;
}

.duration {
  position: absolute;
  right: 8px;
  bottom: 8px;
  background-color: $white;
  color: $black;
  padding: 2px 3px;

  @include text-sm-regular;

  @media screen and (min-width: $breakpoint_l) {
    right: 12px;
    bottom: 12px;
    padding: 4px 6px;

    @include text-base-medium;
  }
}
