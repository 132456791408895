// Text
%text {
  font-weight: $regular_font_weight;
  text-align: left;

  &.size {
    &-xxs {
      @include text_sizes;
    }

    @media screen and (min-width: $breakpoint_xs) {
      &-xs {
        @include text_sizes;
      }
    }

    @media screen and (min-width: $breakpoint_s) {
      &-s {
        @include text_sizes;
      }
    }

    @media screen and (min-width: $breakpoint_m) {
      &-m {
        @include text_sizes;
      }
    }

    @media screen and (min-width: $breakpoint_ml) {
      &-ml {
        @include text_sizes;
      }
    }

    @media screen and (min-width: $breakpoint_l) {
      &-l {
        @include text_sizes;
      }
    }

    @media screen and (min-width: $breakpoint_xl) {
      &-xl {
        @include text_sizes;
      }
    }

    @media screen and (min-width: $breakpoint_xxl) {
      &-xxl {
        @include text_sizes;
      }
    }
  }

  @each $name, $value in $interface_colors {
    &.color-#{$name} {
      color: $value;
    }
  }

  &.align {
    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.lowercase {
    text-transform: none;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  &.bold {
    font-weight: $bold_font_weight;
  }

  &.ellipsis {
    max-width: 100%;
    @include inline_overflow_ellipsis;
  }

  &.error {
    margin: 5px 0;
    color: $maximum-red;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  &.success {
    margin: 5px 0;
    color: $chateau-green;

    &:first-letter {
      text-transform: capitalize;
    }
  }

  &.reduced-spacing {
    letter-spacing: 0.82px;
  }
}

// Inputs
%inputContainerWrapper {
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

%inputContainer {
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:hover,
  &:focus {
    border-color: $mine-shaft;
  }

  &.size {
    &-large {
      padding: 16px $input-container-x-padding-large;
    }

    &-medium {
      padding: 12px $input-container-x-padding-medium;
    }

    &-small {
      padding: 10px $input-container-x-padding-small;
    }
  }

  &.theme {
    &-white {
      border: 0;
      padding: 0;
      background: $white;

      .input,
      .textField {
        padding: 18px 24px;
        background: $white;
        border: 1px solid $light-gray;
        color: $black;

        &::placeholder {
          -webkit-text-fill-color: $black;
          color: $black;
        }

        &:focus-within {
          border: 1px solid $gainsboro;
        }

        &.disabled {
          background: $cultured;
        }
      }

      .label {
        color: $dove-gray;
      }
    }

    &-black {
      background: $god-grey;
      border: 1px solid transparent;

      &:focus-within {
        border: 1px solid $mine-shaft;
      }

      .inputWrapper {
        background: $god-grey;
      }

      .label {
        color: $gainsboro;
      }

      .input,
      .textField {
        background: $god-grey;
        color: $white;

        &::placeholder {
          color: $white;
          -webkit-text-fill-color: $white;
        }
      }
    }
  }

  &.error {
    border-color: $maximum-red;
  }
}

%input {
  margin: 0;
  padding: 0;
  width: 100%;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  color: $black;
  font-style: normal;
  font-weight: $regular_font_weight;
  font-family: inherit;
  appearance: none;

  @include text-lg-regular;

  @media screen and (min-width: $breakpoint_s) {
    @include text-base-regular;
  }

  &::placeholder {
    color: $black;
    -webkit-text-fill-color: $white;
    opacity: 0.4;
  }

  &[disabled],
  &[readonly] {
    &,
    &:hover,
    &:focus,
    &:active {
      color: $black;
      -webkit-text-fill-color: $black;
      user-select: none;
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.4;

      &::placeholder {
        color: $black;
        -webkit-text-fill-color: $black;
        opacity: 0.4;
      }
    }
  }

  @include number_no_arrows;
}

%label {
  margin: 0 0 8px;
  padding: 0;
  display: block;
  color: $gainsboro;
  letter-spacing: -0.2px;

  @include text-xs-medium-uppercase;

  // NO DIFFERENT SIZES YET
  // &.size {
  //   &-large {
  //     @include text-sm-regular;
  //   }

  //   &-medium {
  //     @include text-xs-regular;
  //   }

  //   &-small {
  //     @include text-xs-regular;
  //   }
  // }
}

%errorHint {
  margin: 8px 0 0;
  text-align: left;
  color: $maximum-red;

  &.size {
    &-large {
      margin: 8px 0 0;
    }

    &-medium {
      margin: 8px 0 0;
    }

    &-small {
      margin: 8px 0 0;
    }
  }
}

// Buttons
%button-reset {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;

  &,
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  &[disabled],
  &[disabled] > * {
    cursor: not-allowed;
    user-select: none;
    pointer-events: none;
  }
}

%button {
  position: relative;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: $semi_bold_font_weight;
  font-family: "GT America", sans-serif;
  margin: 0;

  @each $name, $value in $interface_colors {
    &.color-#{$name} {
      background-color: $value;
      border-width: 1px;
      border-style: solid;
      border-color: $value;

      @if $name == "harvest-gold" {
        color: $black;
      } @else if $name == "white" {
        color: $black;
      } @else if $name == "chateau-green" {
        color: $black;
      } @else if $name == "transparent" {
        color: $black;
        border-color: $light-gray;
        background-color: transparent;
      } @else if $name == "transparent-gold" {
        color: $harvest-gold;
        border-color: $harvest-gold;
        background-color: transparent;
      } @else if $name == "black-gold" {
        color: $harvest-gold;
        border-color: $harvest-gold;
        background-color: $black;
      } @else {
        color: $white;
      }

      .buttonIcon {
        @if $name == "harvest-gold" {
          color: $black;
        } @else if $name == "white" {
          color: $black;
        } @else if $name == "black-gold" {
          color: $harvest-gold;
        } @else if $name == "black" {
          color: $harvest-gold;
        } @else if $name == "transparent" {
          color: inherit;
        } @else if $name == "transparent-gold" {
          color: inherit;
        } @else {
          color: $white;
        }
      }

      &:hover:not([disabled]) {
        cursor: pointer;

        @if $name == "harvest-gold" {
          background-color: darken($harvest-gold, 5.2);
          border-color: darken($harvest-gold, 5.2);
        } @else if $name == "white" {
          background-color: $cultured;
          border-color: $cultured;
        } @else if $name == "transparent" {
          background-color: $cultured;
          border-color: $light-gray;
        } @else if $name == "transparent-gold" {
          background-color: rgba($black, 0.15);
          border-color: darken($harvest-gold, 3.2);
        } @else if $name == "black-gold" {
          border-color: darken($harvest-gold, 3.2);
        } @else {
          background-color: darken($value, 3.2);
          border-color: darken($value, 3.2);
        }
      }

      &:focus,
      &:active {
        outline: none;

        @if $name == "harvest-gold" {
          background-color: darken($harvest-gold, 8.2);
          border-color: darken($harvest-gold, 8.2);
        } @else if $name == "white" {
          background-color: darken($cultured, 3.2);
          border-color: darken($cultured, 3.2);
        } @else if $name == "transparent" {
          background-color: $cultured;
          border-color: $light-gray;
        } @else if $name == "transparent-gold" {
          background-color: rgba($black, 0.2);
          border-color: darken($harvest-gold, 8.2);
        } @else if $name == "black-gold" {
          border-color: darken($harvest-gold, 8.2);
        } @else {
          background-color: darken($value, 8.2);
          border-color: darken($value, 8.2);
        }
      }
    }
  }

  &.size {
    &-xs {
      @include button_sizes;
    }

    @media screen and (min-width: $breakpoint_s) {
      &-s {
        @include button_sizes;
      }
    }

    @media screen and (min-width: $breakpoint_m) {
      &-m {
        @include button_sizes;
      }
    }

    @media screen and (min-width: $breakpoint_ml) {
      &-ml {
        @include button_sizes;
      }
    }

    @media screen and (min-width: $breakpoint_l) {
      &-l {
        @include button_sizes;
      }
    }

    @media screen and (min-width: $breakpoint_xl) {
      &-xl {
        @include button_sizes;
      }
    }

    @media screen and (min-width: $breakpoint_xxl) {
      &-xxl {
        @include button_sizes;
      }
    }
  }

  &.loading {
    justify-content: center;
    color: transparent;

    .buttonIcon {
      color: transparent;
    }

    .buttonContent {
      border-color: transparent;
      background: transparent;
      color: transparent;
    }
  }

  &.disabled,
  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      opacity: 0.8;
      cursor: not-allowed;
      user-select: none;
      box-shadow: none;

      &:not(.loading) {
        filter: grayscale(1);
      }

      > * {
        user-select: none;
        pointer-events: none;
      }
    }
  }

  input[type="file"] {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;

    &::-webkit-file-upload-button,
    &:hover {
      cursor: pointer;
    }
  }

  .buttonContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: max-content;
  }

  &.withEndIcon,
  &.withStartIcon {
    .buttonContent {
      justify-content: space-between;
    }
  }
}

// Modal
%modalOverlay {
  padding: 0;
  background: rgba($black, 0.8);

  @media screen and (min-width: $breakpoint_l) {
    padding: 24px;
  }
}

%modal {
  margin: 0;
  padding: 24px;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  border-radius: 0;

  @media screen and (min-width: $breakpoint_l) {
    display: inline-block;
    margin: auto;
    min-height: unset;
    padding: 34px 16px;
    max-width: 585px;
    border: 1px solid $god-grey;
    box-shadow: 0 -1px 38px rgba($harvest-gold, 0.04),
      0 -3px 33px rgba($harvest-gold, 0.01),
      0 -3px 34px rgba($harvest-gold, 0.03);
  }

  &.size {
    &-small {
      max-width: unset;
      min-height: 100vh;

      @media screen and (min-width: 615px) {
        max-width: 452px;
        min-height: unset;
      }
    }

    &-medium {
      max-width: 585px;
    }

    &-large {
      max-width: 884px;
    }

    &-extra-large {
      max-width: 1116px;
    }
  }

  &.theme {
    &-white {
      background-color: $white;
    }

    &-black {
      background-color: $black;
    }
  }
}
