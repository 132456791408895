$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &.withTopCutOff {
    top: -8px;

    @media screen and (min-width: $breakpoint_s) {
      top: -14px;
    }

    @media screen and (min-width: $breakpoint_l) {
      top: -24px;
    }

    @media screen and (min-width: $breakpoint_xl) {
      top: -32px;
    }
  }
}

.content {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  font-family: "GT America";
  font-weight: 900;
  font-style: italic;
  font-size: 80.3525px;
  -webkit-text-stroke-width: 3px;
  line-height: 75%;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: transparent;
  user-select: none;
  white-space: nowrap;

  @media screen and (min-width: $breakpoint_s) {
    font-size: 120px;
    -webkit-text-stroke-width: 6px;
  }

  @media screen and (min-width: $breakpoint_l) {
    font-size: 240px;
    -webkit-text-stroke-width: 8px;
  }

  @media screen and (min-width: $breakpoint_xl) {
    font-size: 301.125px;
    -webkit-text-stroke-width: 11.3399px;
  }

  &.black {
    opacity: 0.05;
    -webkit-text-stroke-color: $black;
  }

  &.gold {
    opacity: 0.15;
    -webkit-text-stroke-color: $harvest-gold;
  }

  &.white {
    opacity: 0.05;
    -webkit-text-stroke-color: $white;
  }
}

.contentDouble {
  bottom: 0;

  &.withBottomCutOff {
    bottom: -8px;

    @media screen and (min-width: $breakpoint_s) {
      bottom: -14px;
    }

    @media screen and (min-width: $breakpoint_l) {
      bottom: -24px;
    }

    @media screen and (min-width: $breakpoint_xl) {
      bottom: -32px;
    }

    &.withTopCutOff {
      bottom: -16px;

      @media screen and (min-width: $breakpoint_s) {
        bottom: -28px;
      }

      @media screen and (min-width: $breakpoint_l) {
        bottom: -48px;
      }

      @media screen and (min-width: $breakpoint_xl) {
        bottom: -64px;
      }
    }
  }
}
