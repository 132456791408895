$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.tagsList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: auto;
  padding: 10px 0;
  flex-wrap: wrap;
}

.tagsListItem {
  margin: 0 5px 5px 0;
  border-radius: 12px;
  // this is $harvest-gold color converted from hex to rgba to allow for opacity
  background: rgba(210, 171, 100, 0.15);
}

.hashTag {
  padding: 6px 8px;
  display: block;
  background: transparent;
  box-sizing: border-box;
  color: $harvest-gold;
  font-family: GT America;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  
  @include font_size(10px);

  @media screen and (min-width: $breakpoint_l) {
    font-size: 20px;
    padding: 8px 10px;
  }

  &:hover,
  &:focus {
    background-color: $cultured;
  }
}
