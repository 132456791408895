// Buttons
%button-reset {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;

  &,
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  &[disabled],
  &[disabled] > * {
    cursor: not-allowed;
    user-select: none;
    pointer-events: none;
  }
}
