$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.rootCarousel {
  [class*="baseCardRoot"] {
    display: block;
    width: map-get($cardSizes, small);

    @media screen and (min-width: $breakpoint_l) {
      min-width: map-get($cardSizes, large);
      width: map-get($cardSizes, large);
    }
  }
}

.relatedProductsSection {
  border-top: none;
  padding: 0px;
}
