$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_mixins";
@import "styles3/scss/_base";

.root {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  gap: 6px;
  color: $lights-low;

  @include text-xs-regular;

  @media screen and (min-width: $breakpoint_m) {
    padding-bottom: 40px;
    gap: 12px;

    @include text-2xl;
    text-transform: capitalize;
    font-weight: 400;
    letter-spacing: 0;
  }
}

.link {
  color: $lights-low;
  text-decoration: underline;

  @include text-xs-medium-uppercase;

  @media screen and (min-width: $breakpoint_m) {
    @include text-2xl;
  }
}

.text {
  color: $accent-default;
  text-transform: none;

  @include text-xs-regular;
  font-weight: 700;

  @media screen and (min-width: $breakpoint_m) {
    @include text-2xl;
    text-transform: none;
  }
}
