$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.root {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: stretch;
  align-items: flex-start;

  .header {
    color: $lights-high;
    padding-bottom: 12px;
  }
}

.header {
  @include text-subtitle3;
  text-transform: uppercase;
}

.footer {
  margin-top: $spacing-4;
  width: 100%;
}

.footerCta {
  width: 100%;
}
