$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";
@import "styles3/scss/base";

.root {
  padding: 12px 11px 14px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background: $background-default;
  border: $border-1 solid $border-default;
  border-radius: $radius-1;

  &.active {
    color: $background-dim;
    background-color: $lights-high;

    & .section {
      > p {
        color: $background-dim;
      }
    }

    & .labelItem {
      &:before {
        border-color: $background-dim;
      }
      &:after {
        background: $background-dim;
      }
    }
  }
}

.section {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}

.cardNumber {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;

  &::before {
    content: "…";
    margin: -14px 4px 0 0;

    @include text-3xl;
  }
}

.cardAction {
  padding: 1px 6px;
  color: $error-default;

  @extend %button-reset;
  @include text-lg-regular;

  &:hover,
  &:focus {
    color: darken($error-default, 20%);
  }
}
