// Text
@mixin font_size($pixels) {
  font-size: $pixels;
  font-size: rem($pixels);
}

@mixin line_height($pixels) {
  line-height: $pixels;
  line-height: rem($pixels);
}

@mixin inline_overflow_ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin line_clamp($lines: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  word-break: normal;
  overflow: hidden;
  hyphens: auto;
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Text
@mixin text-h1 {
  font-family: "GT America";
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
}

@mixin text-h2 {
  font-family: "GT America";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
}

@mixin text-h3 {
  font-family: "GT America";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
}

@mixin text-h4 {
  font-family: "GT America";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

@mixin text-h5 {
  font-family: "GT America";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

@mixin text-h6 {
  font-family: "GT America";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

@mixin text-subtitle1 {
  font-family: "GT America";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

@mixin text-subtitle2 {
  font-family: "GT America";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@mixin text-subtitle3 {
  font-family: "GT America";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

@mixin text-body1Regular18 {
  font-family: "GT America";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

@mixin text-body1Medium18 {
  font-family: "GT America";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

@mixin text-body1Regular16 {
  font-family: "GT America";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

@mixin text-body1Medium16 {
  font-family: "GT America";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@mixin text-body2Regular {
  font-family: "GT America";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@mixin text-body2Medium {
  font-family: "GT America";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

@mixin text-body3Regular {
  font-family: "GT America";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

@mixin text-body3Medium {
  font-family: "GT America";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

@mixin text-body4Regular {
  font-family: "GT America";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

@mixin text-body4Medium {
  font-family: "GT America";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

@mixin text-buttonL {
  font-family: "GT America";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@mixin text-buttonM {
  font-family: "GT America";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

@mixin text-buttonS {
  font-family: "GT America";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

@mixin text-captionRegular {
  font-family: "GT America";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

@mixin text-overlineMedium {
  font-family: "GT America";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 160% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

@mixin text-xLargeLabelRegular {
  font-family: "GT America";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@mixin text-xLargeLabelBold {
  font-family: "GT America";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}

@mixin text-largeLabelRegular {
  font-family: "GT America";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@mixin text-largeLabelBold {
  font-family: "GT America";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}

@mixin text-smallLabelRegular {
  font-family: "GT America";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

@mixin text-smallLabelBold {
  font-family: "GT America";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 133.333% */
  text-transform: uppercase;
}

@mixin text-xSmallLabelRegular {
  font-family: "GT America";
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
}

@mixin text-xSmallLabelBold {
  font-family: "GT America";
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px; /* 125% */
  text-transform: uppercase;
}

// Input
@mixin number_no_arrows {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
}

// Grid
@mixin even_grid_columns($number) {
  grid-template-columns: repeat($number, minmax(0, 1fr));
}

@mixin even_grid_columns_dynamic($min-cols, $max-cols, $cols-min-width, $row-gap: 0px, $column-gap: 0px) {
  --min-cols: #{$min-cols};
  --max-cols: #{$max-cols};
  --cols-min-width: #{$cols-min-width};
  --row-gap: #{$row-gap};
  --column-gap: #{$column-gap};
  
  grid-template-columns: repeat(auto-fill, minmax(min((100%/var(--min-cols) - var(--column-gap)*(var(--min-cols) - 1)/var(--min-cols)), max(var(--cols-min-width), (100%/var(--max-cols) - var(--column-gap)*(var(--max-cols) - 1)/var(--max-cols)))), 1fr));
  gap: var(--row-gap) var(--column-gap);
}

// Image
@mixin standard_image {
  margin: auto;
  display: block;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center center;
}

@mixin hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

@mixin black-scrollbar {
  /* For Webkit browsers (Chrome, Safari, Edge) */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #525254;
    border-radius: 5px; // Radius-1
  }

  /* For Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #525254 transparent;  /* thumb and track color */
  }
}

@mixin grain-overlay($opacity: 0.09) {
  position: relative;
  isolation: isolate;
  
  &::before, &::after {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
  }
  
  &::before {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a'%3E%3CfeTurbulence baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23a)'/%3E%3C/svg%3E");
    mix-blend-mode: multiply;
    opacity: $opacity;
  }
  
  &::after {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 300 300' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='b'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.8' numOctaves='4'/%3E%3CfeColorMatrix type='saturate' values='0'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23b)'/%3E%3C/svg%3E");
    mix-blend-mode: soft-light;
    opacity: $opacity * 1.2;
  }
}

@mixin mobile-safe-area {
  padding-top: 71px
}

// Input
@mixin number_no_arrows {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
}


