$cdn: 'https://cdn.millions.co';
@import "styles3/scss/_base";

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  outline: none;
}

.infoWrapper {
  height: 78px;
  max-height: 78px;

  @media screen and (min-width: $breakpoint_l) {
    height: 92px;
    max-height: 92px;
  }
}
