$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

.paymentCardsWrapper {
  width: 100%;
  list-style: none;
  display: flex;
  flex-flow: column wrap;
  justify-content: stretch;
  align-items: flex-start;
  gap: 4px;
}

.paymentCardItem {
  width: 100%;
}
