$cdn: 'https://cdn.millions.co';
@import "styles3/scss/base";

@font-face {
  font-family: "slick-override";
  src: url("#{$cdn}/fonts/slick/slick.eot");
  src: url("#{$cdn}/fonts/slick/slick.eot?#iefix") format("embedded-opentype"),
    url("#{$cdn}/fonts/slick/slick.woff") format("woff"),
    url("#{$cdn}/fonts/slick/slick.ttf") format("truetype"),
    url("#{$cdn}/fonts/slick/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.carouselWrapper {
  [class*="slick-list"] {
    overflow-y: visible;
    height: max-content;
  }

  [class*="slick-dots"] {
    bottom: auto;

    li {
      margin: 0;
      width: 32px;
      height: 10px;

      &:last-child {
        margin: 0;
        width: 10px;
      }

      button {
        margin: 0;
        padding: 0;
        width: 10px;
        height: 10px;

        &:before {
          width: 10px;
          height: 10px;
          font-size: 10px;
          line-height: 10px;
          color: $lights-low;
          opacity: 1;
          font-family: "slick-override";
        }

        &:hover {
          &:before {
            color: $accent-default;
          }
        }
      }

      &[class*="slick-active"] button:before {
        color: $accent-default;
        opacity: 1;
      }
    }
  }
}
